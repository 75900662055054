export const header = {
  homepage: "https://erxcc.dev/",
  title: "EL.",
};

export const about = {
  name: "ERIC",
  role: "Software Developer",
  description:
    "Experienced Python developer with over three years of expertise in automating tasks, web scraping, API integration, and data handling and much more. Recently, I’ve become increasingly fascinated by Web3 technologies and have been building projects with Web3 technologies. I excel at solving problems, debugging, and building efficient solutions. Passionate about leveraging technology to streamline processes, I bring creativity, precision, and a strong work ethic to every project I undertake. I am currently learning JavaScript/TS and React to further my skills in web development.",
  resume:
    "https://rxresu.me/erxcc/python-developer",
  social: {
    linkedin: "https://www.linkedin.com/in/eric-lin1/",
    github: "https://github.com/erxcc",
  },
};

export const projects = [
  {
    name: "Wrapped Now",
    description: "Wrapped Now is a web application that provides real-time Spotify listening analytics. Users can log in with their Spotify account to instantly view their personalized music insights, top artists, genre breakdowns, and listening patterns. Unlike Spotify Wrapped which is yearly, Wrapped Now gives you your music journey analytics anytime, presented through a beautiful interface with dark/light mode support.",
    stack: ["Python", "Flask", "Spotify API", "CSS", "React", "JavaScript"],
    livePreview: "https://wrappednow.xyz/"
  },
  {
    name: "TheSoleHub",
    description:
      "TheSoleHub is a sneaker consignment platform for sneaker stores, designed to help increase inventory quantity without the need to invest heavily in purchasing stock. Utilising Shopify's API, we are able to auto consign orders to sellers and notify sellers through email notifications, and provide a platform for sellers to manage their inventory.",
    stack: ["Python", "Flask", "MongoDB", "smtplib", "Threading", "APIs", "HTML", "CSS", "JavaScript"],
    livePreview: "https://thesolehub.com/",
  },
  {
    name: "TheUnityGroup",
    description:
      "TheUnityGroup is a platform to help Church's and Mosques generate donations links and manage their donations. Additional features such as mass email and sms notifications allows Church's and Mosques to keep their members up to date with events and news.",
    stack: ["Python", "Stripe", "HTML", "CSS", "JavaScript", "React"],
    livePreview: "https://theunitygroup.co.uk/",
  },
  {
    name: "EngageX",
    description:
      "EngageX helps automote tasks on X (Twitter). Without the need to purchase X's API, EngageX allows users to automate their Twitter accounts with ease. EngageX is CLI and offers a variety of features, including auto-follow, auto-like, auto-retweet, and auto-reply. With EngageX, users can save time, increase engagement, and grow their audience on X.",
    stack: ["Python", "OpenAI", "Requests", "Threading", "APIs", "CLI"],
    livePreview: "https://engagex.site/",
  },
  {
    name: "Solana Automation Software",
    description:
      "A CLI software built to snipe memecoins on the Solana blockchain. Provding our own in-house build monitors to monitor all memecoins on chain, users can snipe memecoins with their own custom filters. We also provide features to allow users to monitor other platforms such as X, Telegram and Discord.",
    stack: ["Python", "Requests", "Solana", "RPC", "Selenium", "Threading", "APIs", "CLI"],
    livePreview: "https://github.com/erxcc",
  },
  {
    name: "Nova Chat Bot",
    description:
      "Nova Chat Bot is an interactive chat bot that helps users with their queries. Nova is powered by OpenAI's API, however, this is currently disabled due to high costs. Nova was developed to help develop my frontend and JavaScript skills.",
    stack: ["JavaScript", "CSS", "HTML", "Netlify"],
    livePreview: "http://nova-chat.xyz/",
  },
  {
    name: "Movie Match",
    description:
      "Movie Match is a user-friendly web application that allows movie enthusiasts to search and discover films with ease. Movie Match uses the TMDB api to get real time data. Movie Match aims to be a go-to platform for anyone looking to explore their next favorite film.",
    stack: ["Python", "Django", "APIs", "HTML", "CSS", "JavaScript"],
    livePreview: "https://www.moviematch.site/",
  },
  {
    name: "Feeling Foodie",
    description:
      "Feeling Foodie is an interactive web application that helps users explore various cuisines and discover new recipes based on their preferences. The platform allows users to browse through different types of cuisines, read about them, and find corresponding recipes to cook at home.",
    stack: ["Python", "Flask", "JavaScript", "CSS", "HTML", "APIs", "Render"],
    livePreview: "https://feelingfoodie.onrender.com/",
  },

];

export const skills = [
  "Python",
  "PyTorch",
  "OpenAI",
  "Data Analysis",
  "Flask",
  "Django",
  "Requests",
  "Web Scraping",
  "Selenium",
  "Threading",
  "APIs",
  "CLI",
  "VS Code",
  "Discord.py",
  "MongoDB",
  "GitHub",
  "HTML",
  "CSS",
  "JavaScript",
  "GoLang",
  "AWS",
  "MySQL",
  "Playwright",
  "PyTest"
];

export const contact = {
  email: "ericlin246@outlook.com",
};
